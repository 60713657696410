import React from 'react';

/**
 * Route not found page
 */
const NotFound = () => (
  <center style={{ marginTop: '50px' }}>
    <h1>You&apos;ve reached the edge of the universe</h1>
  </center>
);

export default NotFound;
